<template>

  <r-e-dialog title="详情" class="layer-contract-detail" :visible.sync="detailsDialogVisible"
              top="10vh" width="1700px" @click-submit="detailsDialogVisible=false"
              @click-cancel="detailsDialogVisible=false" @close="detailsDialogVisible=false">

    <section class="rentStatistics">
      <div style="height: 50px;" class="flex justify-end align-center" v-if="isShowBack">
        <el-button type="primary" size="small" icon="el-icon-arrow-left" @click="goBack">返回</el-button>
      </div>
      <div class="top-bar bg-white" style="border-radius: 10px;margin: 20px 0;">
        <div style="width: 100%;">
          <div class="title">租户信息</div>
          <div class="flex align-center" style="height: 40px;">
            <div class="item" style="width: 22%;">
              租户姓名: {{ formSearch ? formSearch.leasor.name : "" }}
            </div>
            <div class="item" style="width: 22%;">
              租户电话: {{ formSearch ? formSearch.leasor.tel1 : "" }}
            </div>
            <div class="item" style="width: 22%;">
              身份证: {{ formSearch ? formSearch.leasor.idcard : "" }}
            </div>
            <div class="item" style="width: 22%;">
              合同起止: {{
                formSearch ? timeFormat(new Date(formSearch.contract.startDate)) + " 至 " + timeFormat(new
                Date(formSearch.contract.endDate)) : ""
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="top-bar bg-white" style="border-radius: 10px;margin: 20px 0;">
        <div style="width: 100%;">
          <div class="title">账单信息</div>
          <div class="flex align-center" style="height: 40px;">
            <div class="item" style="width: 22%;">
              账单编号: {{ formSearch ? formSearch.billNum : "" }}
            </div>
            <div class="item" style="width: 22%;">
              账单类型: {{ formSearch ? formSearch.typeName : "" }}
            </div>
            <div class="item" style="width: 22%;">
              缴费状态: {{ formSearch ? formSearch.statusCodeName : "" }}
            </div>
            <div class="item" style="width: 22%;font-size: 14px;cursor:pointer;" @click="billSwitch=true">
              <el-link :underline="false">
                支付渠道: {{ formSearch ? (formSearch.paymentChannel ? formSearch.paymentChannel : "无") : "" }}
              </el-link>
            </div>
          </div>
          <div class="flex align-center" style="height: 40px;">
            <div class="item" style="width: 22%;">
              合同编号: {{ formSearch ? formSearch.contract.contractNum : "" }}
            </div>
            <div class="item" style="width: 22%;">
              账单周期: {{
                formSearch ? timeFormat(new Date(formSearch.startDate)) + " 至 " + timeFormat(new
                Date(formSearch.endDate)) : ""
              }}
            </div>
            <div class="item" style="width: 22%;">
              账单金额: {{ formSearch ? numberFormat(formSearch.receivableAmount / 100) + "元" : "无" }}
            </div>
            <div class="item" style="width: 22%;">
              已付金额:
              {{ amountData && amountData.paidAmount ? numberFormat(amountData.paidAmount / 100) + "元" : "无" }}
            </div>
          </div>
          <div class="flex align-center" style="height: 40px;">
            <div class="item" style="width: 22%;">
              未付金额:
              {{ amountData && amountData.noPaidAmount ? numberFormat(amountData.noPaidAmount / 100) + "元" : "无" }}
            </div>
            <div class="item" style="width: 22%;">
              付款时间:
              {{ formSearch && formSearch.paidDate ? timeFormat(new Date(formSearch.paidDate), "yyyy-MM-dd HH:mm:ss") : "无" }}
            </div>
            <div v-if="formSearch&&formSearch.hydropowerLog" class="item" style="width: 30%;font-size: 14px;">
              <el-link :underline="false" @click="goHydropowerRecord">
                上次读数:
                {{ formSearch ? (formSearch.typeName === "电费" ? (formSearch.hydropowerLog.lastElect + "kW·h") : (formSearch.hydropowerLog.lastWater + "m³")) : "" }}
                ----
                本次读数:
                {{ formSearch ? (formSearch.typeName === "电费" ? (formSearch.hydropowerLog.elect + "kW·h") : (formSearch.hydropowerLog.water + "m³")) : "" }}
              </el-link>
            </div>
          </div>
          <div class="flex align-center" style="height: 40px;">
            <div style="font-size: 14px;">
              备注: {{ formSearch ? formSearch.comment : "" }}
            </div>
          </div>
        </div>
      </div>
      <div class="top-bar bg-white" style="border-radius: 10px;margin: 20px 0;">
        <div style="width: 100%;">
          <div class="title">房源信息</div>
          <div class="flex align-center" style="height: 40px;">
            <div class="item" style="width: 22%;">
              小区名称: {{ formSearch ? formSearch.contract.apartment.community.name : "" }}
            </div>
            <div class="item" style="width: 22%;">
              座落: {{ formSearch ? formSearch.contract.apartment.name : "" }}
            </div>
            <div class="item" style="width: 22%;">
              所属区域: {{ formSearch ? formSearch.contract.apartment.community.district : "" }}
            </div>
          </div>
          <div class="flex align-center" style="height: 40px;">
            <div class="item" style="width: 22%;">
              住房类型: 长租公寓
            </div>
            <div class="item" style="width: 22%;">
              户型: {{ formSearch ? formSearch.contract.apartment.apartmentType : "" }}
            </div>
            <div class="item" style="width: 22%;">
              整套面积: {{ formSearch ? formSearch.contract.apartment.outsideArea : "" }}
            </div>
          </div>
        </div>
      </div>
      <div class="top-bar bg-white" style="border-radius: 10px;margin: 20px 0;">
        <div style="width: 100%;">
          <div class="title">核销图片</div>
          <div class="flex ">
            <div v-for="(item,index) in imgList" :key="index" class="item" style="width: 22%">
              <div class="flex justify-center img_div">
                <el-image class="flex align-center" :src="createFullImageUrl(item)"
                          :preview-src-list="[createFullImageUrl(item)]">
                  <div slot="error" style="font-size: 30px;"><i class="el-icon-picture-outline"/></div>
                  <div slot="placeholder">加载中...</div>
                </el-image>
              </div>
            </div>
            <div class="item" style="width: 22%;">
              核销人: {{ formSearch ? formSearch.writeOff : "" }}
            </div>
          </div>
        </div>
      </div>
      <r-e-dialog title="支付明细" :visible.sync="billSwitch" show-footer top="5vh" width="1000px" :showFooter="false"
                  v-dialog-drag>
        <r-e-table ref="tableRef" :data="formSearch&&formSearch.paymentDetails?formSearch.paymentDetails:[]"
                   :height="500" :showPagination="false" show-summary :summary-method="getSummaries">
          <el-table-column prop="comment" label="支付时间">
            <template slot-scope="{ row }">
              {{ timeFormat(new Date(row.paidDate), 'yyyy-MM-dd HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column prop="paymentChannel" label="支付方式"/>
          <el-table-column prop="typeName" label="账单类型"/>
          <el-table-column prop="receivableAmount" label="支付金额">
            <template slot-scope="{ row }">
              {{ numberFormat(row.receivableAmount / 100) }}
            </template>
          </el-table-column>
        </r-e-table>
      </r-e-dialog>
    </section>

  </r-e-dialog>

</template>

<script>
import {paybillDetails, getAmountData} from "@/api/bill";
import {timeFormat, numberFormat} from "@custom/index";
import {createFullImageUrl} from "@/components/Upload/index";

export default {
  name: "approval-xiangqing",
  props: {


  },
  components: {},
  data() {
    return {
      detailsDialogVisible:false,
      formSearch: null,
      imgList: [],
      billSwitch: false,
      billData:{},
      amountData: null,
      isShowBack: false,
    };
  },
  methods: {
    async paybillDetails(uuid) {
      const res = await paybillDetails(uuid);
      const {info} = res;
      this.formSearch = info;
      const {imgs} = info;
      this.imgList = imgs ? imgs.split(";") : [];
      await this.getAmount(uuid);
      this.detailsDialogVisible = true
    },
    async getAmount(uuid) {
      const res = await getAmountData({uuid});
      const {info} = res;
      const {paymentDetails} = info;
      if (this.formSearch) this.formSearch.paymentDetails = paymentDetails;
      this.amountData = info;

    },
    timeFormat(date, format = 'yyyy-MM-dd') {
      return timeFormat(date, format);
    },
    numberFormat(data) {
      return numberFormat(data);
    },
    createFullImageUrl(uuid) {
      return createFullImageUrl(uuid)
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) return prev + curr;
            else return prev;
          }, 0);
          sums[index] = numberFormat(sums[index] / 100);
        } else sums[index] = '';

      });
      return sums;
    },
    goHydropowerRecord() {
      const {hydropowerLog: {apartmentName}} = this.formSearch;
      this.$router.push({name: "door-management", params: {data: {apartmerntName: apartmentName}}});
      console.log(this.formSearch.hydropowerLog.apartmentName);
    },
    // 初始化
   async goBack (data) {
      this.billData = data;
     if (this.billData && typeof (this.billData.billUuid) !== 'undefined') {
       const loading = this.$loading({
         lock: true,
         text: 'Loading',
         spinner: 'el-icon-loading',
         background: 'rgba(0, 0, 0, 0.7)'
       });
       let {billUuid} = this.billData;
       this.isShowBack = false;
       await this.paybillDetails(billUuid).finally(() => loading.close());
     }
    },
  },
   mounted() {

  }
}
</script>

<style lang="scss" scoped>
.rentStatistics {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(10px);
    padding: VH(15px) VW(15px);
    justify-content: center;

    .el-radio-button.is-active {
      box-shadow: 0 0 10px #DDD inset;
    }
  }

  /deep/ .r-e-table {
    .el-table .cell {
      white-space: nowrap;
      text-align: center;
    }
  }

  .title {
    height: VH(50px);
    line-height: VH(50px);
    color: #666;
    padding-left: VW(10px);
    position: relative;

    &::before {
      width: 5px;
      height: 40%;
      background-color: #5C84FB;
      content: '';
      position: absolute;
      left: 0;
      top: 30%;
    }
  }

  .item {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .img_div {
    background: #f5f7fa;
    width: 150px;
    height: 100px;
    border: 1px solid #F1F1F3;
    border-radius: 5px;
    margin: 0 10px
  }
}
</style>
